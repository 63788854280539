import React from "react"

function HeroRight() {
  return (
    <svg className="hero__right" viewBox="0 0 1255 900">
      <g>
        <path
          shapeRendering="auto"
          fill="url(#hero_gradient)"
          d="M1255 0H545.375L296.866 199.354c-106.199 85.193-52.917 256.229 81.966 274.743C708.364 519.329 951.697 608.24 1255 870V0z"></path>
      </g>
      <g fill="#000" fillOpacity="0.1" filter="url(#stripes_filter)">
        <path
          className="stripe"
          shapeRendering="auto"
          d="M1170.04 0h-39.21c40.48 30.19 81.74 63.407 124.17 100.008v-30c-28.82-24.86-57.1-48.158-84.96-70.008zM1255 50.008c-20.2-17.423-40.13-34.08-59.84-50.008H1255v50.008zM1255 120.008C1202.98 75.139 1152.73 35.354 1103.5 0h-43.1c62.99 42.438 127.32 91.97 194.6 150.008v-30zM1255 170.008C1176.86 102.603 1102.7 46.67 1030.01 0h-48.666C1069.66 52.182 1159.35 117.5 1255 200.008v-30zM1255 220.008C1146.72 126.601 1046.07 55.225 946.407 0h-57.213C1007.79 58.689 1125.97 138.708 1255 250.008v-30z"></path>
        <path
          className="stripe"
          shapeRendering="auto"
          d="M1255 270.008C1110.67 145.507 979.909 60.146 846.956 0h-71.814C933.456 60.796 1084.79 153.185 1255 300.008v-30z"></path>
        <path
          className="stripe"
          shapeRendering="auto"
          d="M1255 320.008C1064.96 156.078 898.447 60.006 719.479 0H617.876C837.191 56.55 1029.54 155.523 1255 350.008v-30z"></path>
        <path
          className="stripe"
          shapeRendering="auto"
          d="M1255 370.008C1004.07 153.547 794.144 55.402 542.41 2.378l-29.863 23.957C777.716 77.875 994.208 175.044 1255 400.008v-30z"></path>
        <path
          className="stripe"
          shapeRendering="auto"
          d="M1255 420.008C987.626 189.366 766.815 93.05 492.373 42.518L461.73 67.1C750.563 116.117 977.747 210.844 1255 450.008v-30z"></path>
        <path
          className="stripe"
          shapeRendering="auto"
          d="M1255 470.008C971.166 225.167 739.805 131.701 441.055 83.685l-31.362 25.159C723.805 155.394 961.312 246.667 1255 500.008v-30z"></path>
        <path
          className="stripe"
          shapeRendering="auto"
          d="M1255 520.008C954.763 261.017 713.239 171.403 388.562 125.796l-30.426 24.407a166.183 166.183 0 0021.164 4.306c329.362 45.209 572.615 134.052 875.7 395.499v-30z"></path>
        <path
          className="stripe"
          shapeRendering="auto"
          d="M1255 570.008c-303.085-261.447-546.338-350.29-875.7-395.499-13.793-1.893-26.733-5.381-38.736-10.209l-23.014 18.461c17.927 10.927 38.641 18.576 61.75 21.748 329.362 45.209 572.615 134.052 875.7 395.499v-30z"></path>
        <path
          className="stripe"
          shapeRendering="auto"
          d="M1255 620.008c-303.085-261.447-546.338-350.29-875.7-395.499-29.14-3.999-54.471-15.118-75.209-30.951l-7.225 5.796a162.169 162.169 0 00-10.11 8.814c23.277 23.945 54.699 41.147 92.544 46.341 329.362 45.209 572.615 134.052 875.7 395.499v-30z"></path>
        <path
          className="stripe"
          shapeRendering="auto"
          d="M1255 670.008c-303.085-261.447-546.338-350.29-875.7-395.499-43.135-5.92-77.924-27.439-101.821-56.761a148.705 148.705 0 00-11.262 14.359c23.537 37.171 62.593 65.472 113.083 72.402 329.362 45.209 572.615 134.052 875.7 395.499v-30z"></path>
        <path
          className="stripe"
          shapeRendering="auto"
          d="M1255 720.008c-303.085-261.447-546.338-350.29-875.7-395.499-55.11-7.564-96.598-40.59-119.152-82.819a144.173 144.173 0 00-7.304 14.399c20.08 49.522 64.721 89.947 126.456 98.42 329.362 45.209 572.615 134.052 875.7 395.499v-30z"></path>
        <path
          className="stripe"
          shapeRendering="auto"
          d="M1255 770.008c-303.085-261.447-546.338-350.29-875.7-395.499-66.002-9.059-112.466-54.64-130.265-108.812a145.26 145.26 0 00-4.286 14.364c13.44 60.826 62.283 114.529 134.551 124.448 329.362 45.209 572.615 134.052 875.7 395.499v-30z"></path>
        <path
          className="stripe"
          shapeRendering="auto"
          d="M1255 820.008c-303.085-261.447-546.338-350.29-875.7-395.499-76.43-10.491-126.66-69.954-136.519-135.022-9.331 53.326 10.79 110.225 52.588 146.899-37.311-33.001-57.191-82.171-54.045-130.386 4.533 70.146 56.29 137.297 137.976 148.509 329.362 45.209 572.615 134.052 875.7 395.499v-30z"></path>
      </g>
      <defs>
        <filter
          id="stripes_filter"
          width="1014.47"
          height="860.008"
          x="240.532"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="10"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow"></feBlend>
        </filter>
        <linearGradient
          id="hero_gradient"
          x1="1255"
          x2="1255"
          y1="870"
          y2="0"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--color-2)"></stop>
          <stop offset="1" stopColor="var(--color-1)"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default HeroRight
