import * as React from "react"

function HeroMiddle(props) {
  return (
    <svg className="hero__middle" viewBox="0 0 1255 1206" {...props}>
      <g filter="url(#filter2_d)">
        <path
          shapeRendering="auto"
          d="M1173.7 706.993c46.7-62.904 29.63-150.338-34.58-195.231L715.691 215.715a150.003 150.003 0 00-95.831-26.741l-288.173 19.023c-132.706 8.761-186.259 169.828-80.691 250.718C429.461 595.463 481.182 630.801 595 942c118.608 324.29 430.53-35.462 578.7-235.007z"
          fill="url(#paint2_linear)"
        />
      </g>
      <defs>
        <filter
          id="filter2_d"
          x={0.481201}
          y={0.707947}
          width={1253.59}
          height={1204.53}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={30} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <linearGradient
          id="paint2_linear"
          x1={457}
          y1={200}
          x2={840}
          y2={1037}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--color-4)" />
          <stop offset={1} stopColor="var(--color-3)" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default HeroMiddle
