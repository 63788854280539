// import * as React from "react"

// function HeroLeft(props) {
//   return (
//     <svg className="hero__left" viewBox="0 0 1035 835" {...props}>
//       <g filter="url(#filter1_d)">
//         <path
//           shapeRendering="geometricPrecision"
//           d="M0 0h754l255.73 392.564c58.14 89.264 7.26 208.884-96.783 231.761-233.085 51.249-398.282 100.712-536.06 158.264C207.271 853.439 0 744.348 0 560.53V0z"
//           fill="url(#paint0_linear)"
//         />
//       </g>
//       <defs>
//         <filter
//           id="filter1_d"
//           x={0}
//           y={0}
//           width={1034.17}
//           height={834.392}
//           filterUnits="userSpaceOnUse"
//           colorInterpolationFilters="sRGB">
//           <feFlood floodOpacity={0} result="BackgroundImageFix" />
//           <feColorMatrix
//             in="SourceAlpha"
//             values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//           />
//           <feOffset dy={30} />
//           <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
//           <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
//           <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
//         </filter>
//         <linearGradient
//           id="paint0_linear"
//           x1={14.5}
//           y1={0.000011753}
//           x2={672}
//           y2={744}
//           gradientUnits="userSpaceOnUse">
//           <stop stopColor="var(--color-1)" />
//           <stop offset={1} stopColor="var(--color-2)" />
//         </linearGradient>
//       </defs>
//     </svg>
//   )
// }

// export default HeroLeft

import React from "react"

function HeroLeft() {
  return (
    <svg className="hero__left" viewBox="0 0 1035 835">
      <g filter="url(#filter0_d)">
        <path
          shapeRendering="auto"
          fill="url(#paint0_linear)"
          d="M0 0h754l255.73 392.564c58.14 89.264 7.26 208.884-96.783 231.761-233.085 51.249-398.282 100.712-536.06 158.264C207.271 853.439 0 744.348 0 560.53V0z"></path>
      </g>
      <defs>
        <filter
          id="filter0_d"
          width="1034.17"
          height="834.393"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="30"></feOffset>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="565.75"
          x2="565.75"
          y1="0"
          y2="993"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="var(--color-1)"></stop>
          <stop offset="1" stopColor="var(--color-2)"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default HeroLeft
