import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { motion } from "framer-motion"

interface Props {
  title: string
  mission?: string
  description?: string
  link: string
  img: any
  type: []
  variants?: any
  custom: number
}

const ProjectItem = (props: Props, { image }) => {
  // Stagger Entrance Animation
  const variants = {
    visible: i => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        type: "spring",
        damping: 14,
        bounce: 0.2,
      },
    }),
    hidden: { opacity: 0, y: 500 },
  }

  return (
    <motion.div
      className="project"
      initial="hidden"
      animate="visible"
      variants={variants}
      custom={props.custom}>
      <Link to={props.link} className="project__body">
        <h3 className="project__title">{props.title}</h3>
        <ul>
          {props.type.map((item: any, index: number) => (
            <li className="" key={index}>
              {item}
            </li>
          ))}
        </ul>
        <Img
          className="project__thumbnail"
          fluid={props.img}
          alt="thumbnail"
          style={{ position: "absolute" }}
        />
      </Link>
    </motion.div>
  )
}

export default ProjectItem
